import {
  BriefcaseIcon,
  CurrencyDollarIcon,
  OfficeBuildingIcon,
  TrendingUpIcon,
} from "@heroicons/react/outline";
import BusinessCentralImage from "../images/technologies/bc.png";
import FinanceOperationsImage from "../images/technologies/fno.png";
import WareHouseImage from "../images/technologies/swh.png";

const solutions = [
  {
    name: "Business Central & NAV",
    href: "/contact",
    description:
      "Out of box implementation and customization designed for small to medium-sized businesses with full flexibility to deploy in the cloud or on-premises.",
    imageUrl: BusinessCentralImage,
    icon: BriefcaseIcon,
  },
  {
    name: "Finance and Operations",
    href: "/contact",
    description:
      "Manage all aspects of F&O for large-scale industries, including Advanced Cashflow Management, Production & Shop-floor Management, Financial Reconciliations & Statements, Automatic Financial Closures, and Operational Forecast.",
    imageUrl: FinanceOperationsImage,
    icon: CurrencyDollarIcon,
  },
  {
    name: "Warehouse Management",
    href: "/contact",
    description:
      "Our expertise lies in implementation of Paperless Warehouse Management System with Inventory Optimization. Smart Warehouse Management Solution integral with D365 for shipping & receiving.",
    imageUrl: WareHouseImage,
    icon: OfficeBuildingIcon,
  },
  {
    name: "CRM",
    href: "/contact",
    description:
      "Implement D365 sales & customer services and integrate with external sales and marketing tools to achieve customer and social engagement automations. Connectivity with market and improve the closures. Proactive customer service with smart service team management and scheduling.",
    imageUrl: BusinessCentralImage,
    icon: TrendingUpIcon,
  },
];

export { solutions };
