import React from "react";
import { Link } from "gatsby";
import { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import logo from "../images/logo/full-logo.png";
import Helmet from "react-helmet";
import { navigationSections } from "../data/navigation";
import { solutions } from "../data/solutions";
import { company, mainWebsiteUrl } from "../data/general";

const Navigation = () => {
  return (
    <Popover id="navigation" className="bg-white z-40 fixed w-screen">
      <Helmet
        defaultTitle={company.fullName}
        title={`${company.fullName} - Implement, Integrate & Customize Dynamics Business Central, F&O and
            more D365 Solutions`}
      />
      <div
        className="absolute inset-0 shadow z-30 pointer-events-none"
        aria-hidden="true"
      />
      <div className="relative z-20">
        <div className="max-w-7xl mx-auto flex justify-between items-center px-8 md:py-0 sm:px-6 py-4 lg:px-8 md:justify-start md:space-x-10">
          <div className="mr-[46px]">
            <Link to={mainWebsiteUrl} className="flex">
              <span className="sr-only">{company.fullName}</span>
              <img
                className="h-8 w-auto sm:h-10"
                src={logo}
                alt={company.fullName}
                style={{ height: 50 }}
              />
            </Link>
          </div>
          <div className="-mr-2 -my-2 md:hidden">
            <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
              <span className="sr-only">Open menu</span>
              <MenuIcon className="h-6 w-6" aria-hidden="true" />
            </Popover.Button>
          </div>
          <div className="hidden md:flex-1 md:flex md:items-center md:justify-between">
            {/* Main Nav Button */}
            <div
              className="flex space-x-10 grow justify-center py-6"
              style={{ marginRight: "168px" }}
            >
              {navigationSections.map(({ label, href }) => {
                const menuClassName =
                  "text-base font-medium text-gray-500 hover:text-gray-900";
                return (
                  <Link to={href} className={menuClassName}>
                    {label}
                  </Link>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel
          focus
          className="absolute z-30 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
        >
          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
            <div className="pt-5 pb-6 px-5 sm:pb-8">
              <div className="flex items-center justify-between">
                <div>
                  <img className="h-8 w-auto" src={logo} alt="Workflow" />
                </div>
                <div className="-mr-2">
                  <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                    <span className="sr-only">Close menu</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="mt-6 sm:mt-8">
                <nav>
                  <div className="grid gap-7 sm:grid-cols-2 sm:gap-y-8 sm:gap-x-4">
                    {solutions.map((item) => (
                      <Link
                        key={item.name}
                        to={item.href}
                        className="-m-3 flex items-center p-3 rounded-lg hover:bg-gray-50"
                      >
                        <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-indigo-400 text-white sm:h-12 sm:w-12">
                          <item.icon className="h-6 w-6" aria-hidden="true" />
                        </div>
                        <div className="ml-4 text-base font-medium text-gray-900">
                          {item.name}
                        </div>
                      </Link>
                    ))}
                  </div>
                  <div className="mt-8 text-base">
                    <Link
                      to="#technologies"
                      className="font-medium text-indigo-600 hover:text-indigo-500"
                    >
                      View all products <span aria-hidden="true">&rarr;</span>
                    </Link>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};

export default Navigation;
